<template>
  <div class="container">
    <div>
      <img src="@/assets/404.png" alt="" />
    </div>
    <div class="title">没有找到这个地址对应的网页</div>
    <div class="tips">稍后将带您返回首页</div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
onMounted(() => {
  setTimeout(() => {
    router.push("/");
  }, 2500);
});
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
img {
  width: 500px;
}
.title {
  font-size: 25px;
  margin: 20px 0;
}
.tips {
  font-size: 15px;
  color: gray;
}
</style>
