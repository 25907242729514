import axios from "axios";
import { ElMessage } from "element-plus";

const request = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:7789/api/v1/admin"
      : "https://api.blog.coderdusk.com/api/v1/admin",
  timeout: 5000,
  method: "post",
});

request.interceptors.request.use(
  (config) => {
    const token = localStorage.token;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (error) => {
    if (error.code === "ERR_NETWORK") {
      return ElMessage.error("网络请求错误，请稍后再试");
    } else if (error.response.status === 401) {
      localStorage.removeItem("token");
      location.reload();
      return ElMessage.error("登录信息失效，请重新登录");
    }
    const { data } = error.response;
    return data;
  }
);

export { request };
