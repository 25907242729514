export const permission = {
  install: (app, { router }) => {
    router.beforeEach((to, from, next) => {
      document.title = to.meta.title;
      const { token } = localStorage;
      if (token) {
        if (to.path === "/login") {
          next("/");
        } else {
          next();
        }
      } else {
        if (to.path === "/login") {
          next();
        } else {
          next("/login");
        }
      }
    });
  },
};
