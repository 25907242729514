import LayoutView from "@/views/layout/LayoutView";

export const systemRouter = {
  path: "/system",
  component: LayoutView,
  redirect: "/system/setting",
  name: "system",
  meta: {
    title: "系统设置",
  },
  children: [
    {
      path: "/system/setting",
      name: "system-setting",
      component: () => import("@/views/system/SystemSettingView.vue"),
      meta: {
        title: "系统设置",
      },
    },
  ],
};
