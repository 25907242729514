<template>
  <el-menu
    :default-active="defaultActive"
    active-text-color="#409eff"
    background-color="#545c64"
    text-color="#fff"
    unique-opened
    style="min-height: 100%"
  >
    <side-bar-item
      v-for="(menu, index) in menuList"
      :key="index"
      :menu="menu"
    ></side-bar-item>
    <el-menu-item index="logout" class="single-item-menu" @click="logout"
      >退出登录</el-menu-item
    >
  </el-menu>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import SideBarItem from "./SideBarItem.vue";

import { routes as routesList } from "@/router/index";
let routes = [...routesList];

const filterRoutes = (routes) => {
  return routes
    .filter((item) => !item.meta.hidden)
    .map((item) => {
      if (item.children) {
        item.children = filterRoutes(item.children);
      }
      return item;
    });
};

routes = filterRoutes(routes);

let menuList = ref(routes);

const route = useRoute();
const defaultActive = computed(() => {
  return route.name;
});

const logout = () => {
  localStorage.removeItem("token");
  location.reload();
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.router-link-active {
  text-decoration: none;
}
</style>

<style>
.el-menu-item {
  background-color: rgba(0, 0, 0, 0.1);
}
.el-menu-item.is-active {
  background-color: rgb(67, 74, 80) !important;
}
.single-item-menu {
  background-color: rgb(84, 92, 100);
}
</style>
