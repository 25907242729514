import { createApp } from "vue";
import ElementPlus from "element-plus";
import "reset-css";
import "element-plus/dist/index.css";
import * as Icons from "@element-plus/icons-vue";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";

import App from "./App.vue";
import { router } from "./router";
import store from "./store";
import { permission } from "./plugins/permission";

const app = createApp(App);

Object.keys(Icons).forEach((key) => {
  app.component(key, Icons[key]);
});

app
  .use(store)
  .use(router)
  .use(ElementPlus, {
    locale: zhCn,
  })
  .use(permission, { router });
app.mount("#app");
