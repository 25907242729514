import LayoutView from "@/views/layout/LayoutView";

export const articleRouter = {
  path: "/article",
  component: LayoutView,
  redirect: "/article/list",
  name: "article",
  meta: {
    title: "文章管理",
  },
  children: [
    {
      path: "/article/list",
      name: "article-list",
      component: () => import("@/views/article/ArticleListView.vue"),
      meta: {
        title: "文章列表",
      },
    },
    {
      path: "/article/create",
      name: "article-create",
      component: () => import("@/views/article/ArticleDetailView.vue"),
      meta: {
        title: "新增文章",
      },
    },
    {
      path: "/article/edit/:id",
      name: "article-edit",
      component: () => import("@/views/article/ArticleDetailView.vue"),
      meta: {
        title: "编辑文章",
        hidden: true,
      },
    },
  ],
};
