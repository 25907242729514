<template>
  <router-view />
</template>

<script setup>
import { getSystemUserInfoRequest } from "./api/user";
if (localStorage.token) {
  getSystemUserInfoRequest().then((res) => {
    sessionStorage.siteURL = res.data.user.site_url;
  });
}
</script>

<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100%;
}

.preview-link {
  color: black;
  text-decoration: none;
}
</style>
