import LayoutView from "@/views/layout/LayoutView";

export const readRecordRouter = {
  path: "/readRecord",
  component: LayoutView,
  redirect: "/readRecord/list",
  name: "readRecord",
  meta: {
    title: "阅读管理",
  },
  children: [
    {
      path: "/readRecord/list",
      name: "readRecord-list",
      component: () => import("@/views/read-record/ReadRecordListView.vue"),
      meta: {
        title: "阅读记录列表",
      },
    },
    {
      path: "/readRecord/create",
      name: "read-record-create",
      component: () => import("@/views/read-record/ReadRecordDetailView.vue"),
      meta: {
        title: "新增阅读记录",
      },
    },
    {
      path: "/readRecord/edit/:id",
      name: "read-record-edit",
      component: () => import("@/views/read-record/ReadRecordDetailView.vue"),
      meta: {
        title: "编辑阅读记录",
        hidden: true,
      },
    },
  ],
};
