import { request } from "./request";

// 登录请求
export const loginRequest = (data) => {
  return request({ url: "/user/login", method: "post", data });
};

// 设置密码请求
export const setPasswordRequest = (data) => {
  return request({ url: "/user/modifyPassword", data });
};

// 更新用户信息请求
export const updateSystemSettingRequest = (data) => {
  return request({ url: "/user/updateUserInfo", data });
};

// 获取系统用户信息
export const getSystemUserInfoRequest = () => {
  return request({ url: "/user/getSystemSetting" });
};
