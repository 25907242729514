<template>
  <template v-if="menu.children.length > 1">
    <el-sub-menu :index="menu.name">
      <template #title>
        {{ menu.meta.title }}
      </template>
      <template v-for="(item, index) in menu.children" :key="index">
        <el-menu-item @click="gotoMenuItem(item)" :index="item.name">{{
          item.meta.title
        }}</el-menu-item>
      </template>
    </el-sub-menu>
  </template>
  <template v-else-if="menu.children.length === 1">
    <el-menu-item
      @click="gotoMenuItem(menu.children[0])"
      :index="menu.children[0].name"
      class="single-item-menu"
      >{{ menu.children[0].meta.title }}</el-menu-item
    >
  </template>
</template>
<script setup>
import { defineProps, onMounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const props = defineProps({
  menu: {
    type: Object,
    requied: true,
  },
});
const { menu } = props;

const gotoMenuItem = (item) => {
  router.push(item.path);
};

onMounted(() => {});
</script>

<style lang="scss" scoped>
.single-item-menu {
  background-color: rgb(84, 92, 100);
}
</style>
