<template>
  <div class="background">
    <div class="container">
      <div class="title">系统登录</div>
      <div class="form">
        <el-form ref="loginFormRef" :model="loginForm" :rules="validateRules">
          <el-form-item prop="username">
            <el-input
              v-model="loginForm.username"
              name="username"
              autocomplete="off"
              placeholder="用户名"
            />
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="loginForm.password"
              autocomplete="off"
              placeholder="密码"
              type="password"
              name="password"
            />
          </el-form-item>
        </el-form>
      </div>
      <el-button
        @click="login(loginFormRef)"
        type="primary"
        class="login-button"
        >登录</el-button
      >
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { ElMessage } from "element-plus";
import { loginRequest, getSystemUserInfoRequest } from "@/api/user";
import { useRouter } from "vue-router";

const router = useRouter();
const loginForm = ref({
  username: "",
  password: "",
});
const loginFormRef = ref(null);
const validateRules = ref({
  username: [{ required: true, message: "请输入用户名", trigger: "blur" }],
  password: [{ required: true, message: "请输入密码", trigger: "blur" }],
});

const login = async (el) => {
  try {
    const validateReuslt = await el.validate();
    if (validateReuslt) {
      const loginResult = await loginRequest(loginForm.value);
      if (loginResult) {
        const { error, message, data } = loginResult;
        if (error) {
          ElMessage.error(message);
        } else {
          ElMessage.success(message);
          localStorage.token = data.token;
          const result = await getSystemUserInfoRequest();
          console.log(result);
          router.push("/");
        }
      }
    }
  } catch (err) {
    // ElMessage.error(err.toString());
  }
};
</script>

<style lang="scss" scoped>
.background {
  width: 100%;
  height: 100%;
  background-color: #2d3a4b;
}
.container {
  width: 500px;
  margin: 0 auto;
  padding-top: 150px;
  color: white;
}

.title {
  margin-bottom: 40px;

  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

.input {
  margin-bottom: 20px;
}

.login-button {
  width: 100%;
}
</style>
