<template>
  <el-container>
    <el-aside width="200px">
      <NavigationBar></NavigationBar>
    </el-aside>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script setup>
import NavigationBar from "@/components/NavBar/NavigationBar.vue";
import { watch } from "vue";
import { useRouter } from "vue-router";
const route = useRouter();
</script>

<style lang="scss" scoped>
.el-container {
  height: 100%;
}
</style>
