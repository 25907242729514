import LayoutView from "@/views/layout/LayoutView";

export const projectRouter = {
  path: "/project",
  component: LayoutView,
  redirect: "/project/list",
  name: "project",
  meta: {
    title: "项目管理",
  },
  children: [
    {
      path: "/project/list",
      name: "project-list",
      component: () => import("@/views/project/ProjectListView.vue"),
      meta: {
        title: "项目列表",
      },
    },
    {
      path: "/project/create",
      name: "project-create",
      component: () => import("@/views/project/ProjectDetail.vue"),
      meta: {
        title: "新增项目",
      },
    },
    {
      path: "/project/edit/:id",
      name: "project-edit",
      component: () => import("@/views/project/ProjectDetail.vue"),
      meta: {
        title: "编辑项目",
        hidden: true,
      },
    },
  ],
};
