import { createRouter, createWebHistory } from "vue-router";

import { articleRouter } from "./article";
import { projectRouter } from "./project";
import { readRecordRouter } from "./readRecord";
import { systemRouter } from "./system";
import LayoutView from "@/views/layout/LayoutView";
import LoginView from "@/views/login/LoginView";
import NotFound from "@/views/layout/404PageView";

export const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      hidden: true,
      title: "登录",
    },
  },

  {
    path: "/",
    component: LayoutView,
    redirect: "/article/list",
    meta: {
      hidden: true,
    },
    children: [
      {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: NotFound,
        meta: { title: "没有找到这个页面" },
      },
    ],
  },
  articleRouter,
  projectRouter,
  readRecordRouter,
  systemRouter,
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
